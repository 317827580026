/* eslint-disable */
import Axios from 'axios';

const BASE_URL = process.env.VUE_APP_URL;

const request = {
  requestGET: async (url, token = '', customHeaders = {}) => {
    const header = token === '' ? {
      'Content-Type': 'application/json',
    } : {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      ...customHeaders,
    };
    let response = {};

    await Axios.get(`${BASE_URL}/${url}`, { headers: header })
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    return response;
  },
  requestExternalDataGET: async (url, token = '', customHeaders = {}) => {
    const header = token === '' ? {
      'Content-Type': 'application/json',
    } : {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      ...customHeaders,
    };
    let response = {};

    await Axios.get(url, { headers: header })
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    return response;
  },
  requestPOST: async (url, data, token = '', isForm = false) => {
    let response = {};

    const contentType = isForm === true ? 'multipart/form-data' : 'application/json';
    const header = token === '' ? {
      'Content-Type': contentType,
    } : {
      'Content-Type': contentType,
      Authorization: `Bearer ${token}`,
    };

    await Axios.post(`${BASE_URL}/${url}`, data, { headers: header })
      .then((res) => {
        response = { ...res };
      })
      .catch((error) => {
        response = { ...error };
      });

    return response;
  },
  requestPUT: async (url, data, token = '', isForm = false) => {
    const contentType = isForm === true ? 'multipart/form-data' : 'application/json';
    const header = token === '' ? {
      'Content-Type': contentType,
    } : {
      'Content-Type': contentType,
      Authorization: `Bearer ${token}`,
    };
    let response = {};

    await Axios.put(`${BASE_URL}/${url}`, data, { headers: header })
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    return response;
  },
  requestDELETE: async (url, token = '', isForm = false) => {
    const contentType = isForm === true ? 'multipart/form-data' : 'application/json';
    const header = token === '' ? {
      'Content-Type': contentType,
    } : {
      'Content-Type': contentType,
      Authorization: `Token ${token}`,
    };
    let response = {};

    await Axios.delete(`${BASE_URL}/${url}`, { headers: header })
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    return response;
  },
};

export default request;
