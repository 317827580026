<template>
  <div class="w-full flex flex-col">
    <b-form-group :label="label" v-slot="{ ariaDescribedby }">
      <b-form-checkbox-group
        :id="`checkbox-group-${id}`"
        :value="value"
        :options="options"
        :aria-describedby="ariaDescribedby"
        :name="name"
      ></b-form-checkbox-group>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: 'CheckBox',
  props: {
    label: {
      type: String,
      default: '',
      require: true,
    },
    value: {
      type: Array,
      require: true,
      default: () => [],
    },
    id: {
      type: String,
      default: '',
      require: true,
    },
    name: {
      type: String,
      default: '',
      require: true,
    },
    options: {
      type: Array,
      default: () => [],
      require: true,
    },
  },
};
</script>
