import { render, staticRenderFns } from "./Icon.vue?vue&type=template&id=c1e04bf8&class=flex%20inline%20w-4%20h-4&"
import script from "./Icon.vue?vue&type=script&lang=js&"
export * from "./Icon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports