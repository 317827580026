<template>
  <div id="sidebar-variant" class="sidebar">
    <div>
      <router-link class="mb-4 mt-2 mr-6" to="/dashboard"><img :src="loadImg" /></router-link>
      <div
        v-bind:key="option.title" v-for="option in menuOptions">
        <div class="option-container">
          <p @click="toggleSubMenu(option)" class="main-option">
            <icon class="mr-4" :name="option.icon" />
            {{option.title}}
          </p>
          <template class="flex inline w-4 h-4 text-white">
            <component
              v-if="option.subMenu.length"
              class="w-4 h-4 cursor-pointer"
              :class="{isToggle: option.title.toLowerCase() === id}"
              v-on:click.native="toggleSubMenu(option)"
              v-bind:is="loadChevronRight">
            </component>
          </template>
        </div>
        <div v-bind:key="subOption.name" v-for="subOption in option.subMenu">
          <router-link class="no-underline" :to="subOption.route">
            <p
              v-if="option.title.toLowerCase() === id" class="subOption">
              {{subOption.name}}
            </p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from '@/components/icons/Icon.vue';

export default {
  name: 'SideMenu',
  components: { Icon },
  data() {
    return {
      id: '',
      menuOptions: [
        {
          title: 'Escritorio', route: '/dashboard', icon: 'DeskIcon', subMenu: [],
        },
        {
          title: 'Usuarios',
          icon: 'UserIcon',
          subMenu: [
            { name: 'Administradores', route: '/user/admin' },
            { name: 'Empleados', route: '/user/employee' },
            { name: 'Choferes', route: '/user/driver' },
          ],
        },
        {
          title: 'Clientes', icon: 'GroupIcon', route: '/user/client', subMenu: [],
        },
        {
          title: 'Paquetes',
          icon: 'PackageIcon',
          route: '/package',
          subMenu: [
            // { name: 'Ver Paquetes', route: '/package' },
            // { name: 'Ingreso de Paquete', route: '/package/entry' },
            // { name: 'Exenciones', route: '/package/exemption' },
            // { name: 'Validaciones', route: '/package/validation' },
            // { name: 'Validados', route: '/' },
          ],
        },
        {
          title: 'Facturación',
          route: '/package/clear',
          icon: 'InvoiceIcon',
          subMenu: [],
        },
        {
          title: 'AWBs',
          icon: 'PlaneIcon',
          subMenu: [
            { name: 'Ver Awbs', route: '/awbs' },
            { name: 'Escanear', route: '/awbs/scanner' },
          ],
        },
        {
          title: 'Rutas de entrega',
          icon: 'MapIcon',
          subMenu: [
            { name: 'Ver rutas de entrega', route: '/route/delivery' },
            { name: 'Ver cierres', route: '/route/closure' },
            { name: 'Escanear', route: '/route/scanner' },
          ],
        },
        {
          title: 'Reportes',
          icon: 'ReportIcon',
          route: '/reports',
          subMenu: [],
        },
      ],
    };
  },
  computed: {
    loadImg() {
      // eslint-disable-next-line global-require
      // eslint-disable-next-line import/no-unresolved
      // eslint-disable-next-line global-require
      const logoImg = require('@/assets/gv_logo_expressblanco(1).svg');
      return logoImg;
    },
  },
  methods: {
    toggleSubMenu(option) {
      if (option.subMenu.length > 0) {
        if (this.id === option.title.toLowerCase()) {
          this.id = '';
        } else if (option.subMenu.length) {
          this.id = option.title.toLowerCase();
        }
      } else {
        this.$router.push({ path: option.route });
      }
    },
    async loadChevronRight() {
      const icon = await import('@/components/icons/chevronRightIcon.vue');
      return icon;
    },
    async loadChevronDown() {
      const icon = await import('@/components/icons/ChevronDownIcon.vue');
      return icon;
    },
  },
};
</script>
<style scoped>
  .side-menu {
    position: relative;
    display: block;
    min-width: 20%;
    color: var(--primary-white);
  }
  p {
    color: var(--primary-white);
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .option-container {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between;
  }
  .sidebar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0px;
    transition: +0.6s;
    width: 270px;
    padding: 10px 40px 10px 10px;
    background: #0857A3;
    z-index: 0;
  }
  .isToggle {
    @apply transform rotate-90;
  }
  .main-option {
    display: inline;
    display: flex;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-decoration: none;
  }
  .subOption {
    @apply flex inline ml-2 text-sm;
  }

  @media screen and (max-width: 480px) {
    .sidebar {
      width: 150px;
    }
    .main-option {
      @apply text-xs flex inline no-underline cursor-pointer;
    }
    .subOption {
      @apply flex inline ml-2 text-xs;
    }
  }
</style>
