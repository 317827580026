import Vue from 'vue';
import BootstrapVue, { IconsPlugin, DropdownPlugin, TablePlugin } from 'bootstrap-vue';
// Import vue notification library
import Notifications from 'vue-notification';
import App from './App.vue';
import router from './router';
import store from './store';

import './assets/tailwind.css';
import './assets/styles.css';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(DropdownPlugin);
Vue.use(TablePlugin);

Vue.use(Notifications);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

// Truncates text to specified character length  | truncate(150)
Vue.filter('truncate', (text, stop, clamp) => text.slice(0, stop) + (stop < text.length ? clamp || '...' : ''));
