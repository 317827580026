export const CREATE_USER = 'CREATE_USER';
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CLEAR_USER = 'CLEAR_USER';

export default {
  [CREATE_USER]: (state, payload) => { state.user = payload; },
  [SET_USER]: (state, payload) => { state.user = payload; },
  [UPDATE_USER]: (state, payload) => { state.user = payload; },
  [CLEAR_USER]: (state) => { state.user = {}; },
};
