import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('user');

export default {
  computed: mapState({
    user: (state) => state.user,
  }),
  methods: mapActions([
    'createCat',
    'setCat',
    'clearCat',
  ]),
};
