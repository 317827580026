import {
  AUTH_USER,
  CLEAR_TOKEN,
} from './mutations';

const setAuthToken = ({ commit }, payload) => (commit(AUTH_USER, payload));
const clearToken = ({ commit }) => (commit(CLEAR_TOKEN));

export default {
  setAuthToken,
  clearToken,
};
