import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('authentication');

export default {
  computed: mapState({
    sessionToken: (state) => state.sessionToken,
  }),
  methods: mapActions([
    'setSessionToken',
    'clearToken',
  ]),
};
