import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
// import localForage from 'localforage';

// Modules
import user from './modules/user';
import authentication from './modules/auth';
import packages from './modules/package';

// Load Vuex
Vue.use(Vuex);

// const indexedDbModules = ['authentication'];

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  modules: {
    user,
    authentication,
    packages,
  },
  plugins: [vuexLocal.plugin],
});
