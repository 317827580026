import {
  SET_PAYMENT,
  LOAD_PAYMENT,
  LOAD_STATUS,
  CLEAR_PAYMENT,
} from './mutations';

const setPayment = ({ commit }, payload) => commit(SET_PAYMENT, payload);
const loadPayments = ({ commit }, payload) => commit(LOAD_PAYMENT, payload);
const loadStatuses = ({ commit }, payload) => commit(LOAD_STATUS, payload);
const clearPayment = ({ commit }) => (commit(CLEAR_PAYMENT));

export default {
  setPayment,
  loadPayments,
  loadStatuses,
  clearPayment,
};
