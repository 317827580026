<template>
  <div class="nav">
    <div class="container">
      <div class="nav-container flex-row-reverse">
        <!-- <div class="flex mt-2">
          <calendar-icon class="w-4 h-4" />
          <message-icon class="w-4 h-4 mx-2" />
          <note-icon class="w-4 h-4 mr-2" />
          <start-icon class="w-4 h-4" />
        </div> -->
        <div class="right-container">
          <div class="d-flex align-items-center">
            <b-avatar variant="info" src="https://placekitten.com/300/300" class="mx-2"></b-avatar>
            <span class="mr-auto text-base" >{{user.name}}</span>
            <div @click="handleLogOut">
              <Icon name="LogoutIcon" class="ml-2 "/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { BAvatar } from 'bootstrap-vue';
import Icon from '@/components/Icon.vue';
// import StartIcon from '@/components/icons/StartIcon.vue';
// import CalendarIcon from '@/components/icons/CalendarIcon.vue';
// import MessageIcon from '@/components/icons/MessageIcon.vue';
// import NoteIcon from '@/components/icons/NoteIcon.vue';

export default {
  name: 'NavBar',
  components: {
    // StartIcon,
    // CalendarIcon,
    // MessageIcon,
    // NoteIcon,
    Icon,
    BAvatar,
  },
  data() {
    return {
      user: {
        name: 'Alejandro Gonzalez',
      },
    };
  },
  computed: {
    ...mapGetters({
      getTokenSession: 'authentication/getAuthToken',
      getUserLogged: 'user/getUser',
    }),
  },
  methods: {
    ...mapActions({
      clearToken: 'authentication/clearToken',
      clearCat: 'user/clearCat',
    }),
    handleLogOut() {
      if (this.getTokenSession !== '') {
        this.clearToken();
        this.clearCat();
        this.$router.push({ path: '/login' });
      }
    },
  },
};
</script>
<style scoped>
  .nav {
    border-radius: 10px;
    background-color: #ffff;
    margin-top: 1rem;
    padding: 1rem;
    width: 100%;
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .nav-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .right-container {
    display: flex;
  }
</style>
