import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Auth/Login.vue';

import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'Inicio',
    component: () => import(/* webpackChunkName: "Inicio" */ '../views/Home.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/package',
    name: 'Paquete',
    component: () => import(/* webpackChunkName: "package" */ '../views/Package.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/package-detail/:id',
    name: 'Detalle del paquete',
    component: () => import(/* webpackChunkName: "package" */ '../views/PackageDetail.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/package/entry',
    name: 'Paquete de ingreso',
    component: () => import(/* webpackChunkName: "Paquete de ingreso" */ '../views/PackageEntry.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/package/exemption',
    name: 'Exenciones',
    component: () => import(/* webpackChunkName: "Exenciones" */ '../views/PackageExemption.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/package/clear',
    name: 'Clareo',
    component: () => import(/* webpackChunkName: "Clareo" */ '../views/packages/ClearPackage.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/package/validation',
    name: 'package_clear',
    component: () => import(/* webpackChunkName: "package" */ '../views/packages/PackageValidation.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/packages/:id/edit',
    name: 'Editar Paquete',
    component: () => import(/* webpackChunkName: "package" */ '../views/PackageEdit.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/user/admin',
    name: 'Administradores',
    component: () => import(/* webpackChunkName: "Administradores" */ '../views/users/Administrator.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/user/employee',
    name: 'Empleados',
    component: () => import(/* webpackChunkName: "Empleados" */ '../views/users/Employee.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/user/driver',
    name: 'Choferes',
    component: () => import(/* webpackChunkName: "Choferes" */ '../views/users/Driver.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/user/client',
    name: 'Clientes',
    component: () => import(/* webpackChunkName: "Clientes" */ '../views/users/Client.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/awbs',
    name: 'AWBs',
    component: () => import(/* webpackChunkName: "AWBs" */ '../views/awbs/Manifest.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/awbs/scanner',
    name: 'AWBs Escaner',
    component: () => import(/* webpackChunkName: "AWBs Escaner" */ '../views/awbs/Scanner.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/awbs/detail/:id',
    name: 'AWBs Detail',
    component: () => import(/* webpackChunkName: "AWBs Detail" */ '../views/awbs/ManifestDetail.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/route/delivery',
    name: 'route_delivery',
    component: () => import(/* webpackChunkName: "route_delivery" */ '../views/routes/DeliveryRoute.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/route/closure',
    name: 'route_closure',
    component: () => import(/* webpackChunkName: "route_closure" */ '../views/routes/ClosureRoute.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/create_user/:role',
    name: 'Crear Usuario',
    component: () => import(/* webpackChunkName: "Crear Usuario" */ '../views/users/CreateUser.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/user_info/:id',
    name: 'Información Usuario',
    component: () => import(/* webpackChunkName: "Información Usuario" */ '../views/users/UserInfo.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/edit-user/:id',
    name: 'Editar Informacion',
    component: () => import(/* webpackChunkName: "Editar Información" */ '../views/users/EditUsers.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "Editar Información" */ '../views/reports/Index.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
  {
    path: '/report/:id',
    name: 'Report',
    component: () => import(/* webpackChunkName: "Editar Información" */ '../views/reports/Report.vue'),
    meta: {
      requiresAuth: true,
      requiresVisitor: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const hasToken = store.getters['authentication/getAuthToken'];
  const isAuthenticated = hasToken !== '' && hasToken !== undefined;
  if (to.name === 'Login' && isAuthenticated) next({ path: '/dashboard' });
  if (to.name === null && !isAuthenticated) next({ path: '/login' });
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (to.name !== 'Login' && !isAuthenticated) next({ path: '/login' });
    else next();
  } else next();
});

export default router;
