import {
  CREATE_USER,
  SET_USER,
  UPDATE_USER,
  CLEAR_USER,
} from './mutations';

const createCat = ({ commit }, payload) => commit(CREATE_USER, payload);
const setCat = ({ commit }, payload) => commit(SET_USER, payload);
const updateCat = ({ commit }, payload) => commit(UPDATE_USER, payload);
const clearCat = ({ commit }) => (commit(CLEAR_USER));

export default {
  createCat,
  setCat,
  updateCat,
  clearCat,
};
