<template>
  <div class="w-full flex flex-col">
    <section class="w-full flex text-center">
      <div class="banner p-4">
        <img class="w-full h-full" src="@/assets/login.svg" />
      </div>
      <div class="login_box">
        <div class="w-64 text-center m-auto">
          <img src="@/assets/gv_logo_express.png" />
        </div>
        <h2 class="text-center my-2 text-base">Ingresa a tu cuenta</h2>
        <span v-if="error" class="error-msj">
          Usuario o Contraseña inválidos, por favor intente de nuevo.
        </span>

        <input-field-with-icon
          v-model="authUser.email"
          idInput="email"
          idFormGroup="email-group"
          type="email"
          icon="envelope"
          placeholder="Correo Electrónico"
          :error="error"
        />
        <input-field-with-icon
          v-model="authUser.password"
          class="my-4"
          idInput="keyWord"
          idFormGroup="keyWord-group"
          type="password"
          icon="lock"
          placeholder="Contraseña"
          :error="error"
        />
        <div class="my-2 flex w-full justify-between">
          <div>
            <check-box
              id="remember-user"
              name="remember-user"
              v-model="rememberUser"
              :options="checkboxOptions"
            />
          </div>
          <router-link class="no-underline" to="/">
            <p class="text-blue-600 text-sm">¿Olvidaste tu contraseña?</p>
          </router-link>
        </div>
        <b-button @click="handleLogin" class="w-full my-2" variant="primary">
          Iniciar Sesión
        </b-button>
        <p class="w-full flex justify-start text-sm text-gray-400 mr-1 mt-4">
          ¿No tiene una cuenta?
          <router-link class="no-underline" to="/">
            <p class="text-blue-600 text-sm ml-2">Afiliarme</p>
          </router-link>
        </p>
      </div>
    </section>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { mapActions } from 'vuex';
import UserMixin from '@/mixins/UserMixin';
import AuthMixin from '@/mixins/AuthMixin';
import request from '@/api/request-services';
import InputFieldWithIcon from '@/components/InputFieldWithIcon.vue';
import CheckBox from '@/components/CheckBox.vue';

export default {
  name: 'login',
  mixins: [UserMixin, AuthMixin],
  components: { InputFieldWithIcon, CheckBox },
  data() {
    return {
      authUser: {
        email: '',
        password: '',
      },
      error: false,
      rememberUser: [],
      checkboxOptions: [
        { text: 'Recordar', value: 'recordar' },
      ],
    };
  },
  methods: {
    ...mapActions({
      setAuthToken: 'authentication/setAuthToken',
    }),
    async handleLogin() {
      if (this.authUser.email === '' || this.authUser.password === '') {
        this.error = true;
        return;
      }

      const response = await request.requestPOST('login', { email: this.authUser.email, password: this.authUser.password });

      if (response.status === 200) {
        this.setAuthToken(response.data.access_token);
        this.createCat(response.data.fullname);
        this.$router.push({ path: '/dashboard' });
      } else {
        this.error = true;
      }
    },
  },
};
</script>
<style scoped>
  .banner {
    @apply flex h-full self-center text-center;
    width: 60%;
  }
  .login_box {
    @apply flex flex-col h-full text-center bg-white p-4 self-center;
    width: 40%;
  }
</style>
