export const SET_PAYMENT = 'SET_PAYMENT';
export const LOAD_PAYMENT = 'LOAD_PAYMENT';
export const LOAD_STATUS = 'LOAD_STATUS';
export const CLEAR_PAYMENT = 'CLEAR_PAYMENT';

export default {
  [SET_PAYMENT]: (state, payload) => { state.payment = payload; },
  [LOAD_PAYMENT]: (state, payload) => { state.payments = payload; },
  [LOAD_STATUS]: (state, payload) => { state.statuses = payload; },
  [CLEAR_PAYMENT]: (state) => { state.payment = {}; },
};
