<template>
  <div class="w-full">
    <label class="text-sm">{{label}}</label>
    <b-input-group class="focus:outline-none text-sm" :prepend="prepend" :append="append">
      <b-form-input
        class="focus:outline-none text-sm"
        :id="idInput"
        :class="{'error-input': error}"
        :value="value"
        :type="type"
        @input="setInput"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled">
      </b-form-input>
      <b-input-group-append v-if="icon">
        <b-input-group-text>
          <b-icon class="w-4" :icon="icon" />
        </b-input-group-text>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>
<script>
import {
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue';

export default {
  name: 'InputField',
  components: { BInputGroup, BFormInput },
  methods: {
    setInput(value) {
      this.$emit('input', value);
    },
  },
  props: {
    idInput: {
      type: String,
      require: true,
    },
    prepend: {
      type: String,
      default: '',
      require: true,
    },
    append: {
      type: String,
      default: '',
      require: true,
    },
    icon: {
      type: String,
      default: '',
      require: false,
    },
    idFormGroup: {
      type: String,
      require: true,
    },
    type: {
      type: String,
      default: 'text',
      require: true,
    },
    label: {
      type: String,
      default: '',
      require: false,
    },
    helpText: {
      type: String,
      default: '',
      require: false,
    },
    value: {
      require: true,
    },
    placeholder: {
      type: String,
      default: '',
      require: false,
    },
    required: {
      type: Boolean,
      default: false,
      require: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      require: false,
    },
    error: {
      type: Boolean,
      default: false,
      require: false,
    },
  },
};
</script>
