<template>
  <div class="w-full flex fixed bottom-0 py-3 justify-between bg-white p-2 mt-6">
    <div class="flex flex-col">
      <div class="text-xs">
        Copyright 2021
        <router-link to="https://stage.legacy.gvexpress.cr/" class="text-blue-500 text-xs no-underline">GV Express</router-link>.
        Todos los derechos reservados.
      </div>
    </div>
    <div>
      <div class="text-xs">Creado por Bamboo CR.</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer',
};
</script>
