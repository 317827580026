<template>
  <div class="h-full flex flex-col page-wrap" id="app">
    <div v-if="getAuthToken">
      <notifications/>
      <div class="page-content h-full mb-20">
        <nav-bar />
        <router-view class="h-full flex flex-col bottom-0"/>
      </div>
      <div class="footer">
        <Footer />
      </div>
      <side-menu />
    </div>
    <div v-else class="flex flex-col w-full h-full justify-between">
      <notifications/>
      <router-view />
      <Footer />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SideMenu from '@/components/SideMenu.vue';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: { SideMenu, NavBar, Footer },
  computed: {
    ...mapGetters({
      getAuthToken: 'authentication/getAuthToken',
    }),
  },
};
</script>
<style scoped>
  @import url('https://fonts.cdnfonts.com/css/freesans');
  .page-wrap {
    overflow-x: hidden;
  }
  .page-content {
    width: 81%;
    position: relative;
    z-index: 0;
    padding: 10px 15px;
    padding-bottom: 0;
    transition: +0.6s;
    transform: translateX(290px);
  }
  .footer {
    width: 85%;
    position: fixed;
    z-index: 0;
    bottom: 0;
    padding: 10px 15px;
    padding-bottom: 0;
    transition: +0.6s;
    transform: translateX(255px);
  }

  @media screen and (max-width: 480px) {
    .page-content {
      width: 81%;
      position: relative;
      display: flex;
      z-index: 0;
      padding: 10px 15px;
      transition: +0.6s;
      transform: translateX(150px);
      bottom: 0;
    }
  }

</style>
