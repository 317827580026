<template>
  <component class="w-4 h-4" :is="loadIcon"></component>
</template>
<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      require: true,
    },
  },
  methods: {
    async loadIcon() {
      const icon = await import(`@/components/icons/${this.$props.name}.vue`);
      return icon;
    },
  },
};
</script>
