<template class="flex inline w-4 h-4">
  <component class="w-4 h-4" v-bind:is="iconComponent"></component>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: 'StartIcon',
      required: true,
    },
  },
  methods: {
    async iconComponent() {
      const icon = this.name ? await import(`@/components/icons/${this.name}.vue`) : null;
      return icon;
    },
  },
};
</script>
